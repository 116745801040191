import React from "react";
import PropTypes from "prop-types";
import { Provider as ReduxProvider } from "react-redux";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import localeData from "react-intl/locale-data/en";

const ContextType = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: PropTypes.func.isRequired,
  // Universal HTTP client
  fetch: PropTypes.func.isRequired,
  cookies: PropTypes.object.isRequired,
  // Integrate Redux
  // http://redux.js.org/docs/basics/UsageWithReact.html
  ...ReduxProvider.childContextTypes,
  pathname: PropTypes.string.isRequired,
  query: PropTypes.object,
  // ReactIntl
  intl: IntlProvider.childContextTypes.intl,
  antLocale: PropTypes.object,
  locale: PropTypes.string,
};

class App extends React.PureComponent {
  static propTypes = {
    context: PropTypes.shape(ContextType).isRequired,
    children: PropTypes.element.isRequired,
  };

  static childContextTypes = ContextType;

  getChildContext() {
    const { locale, antLocale, ...restContext } = this.props.context;
    return {
      ...restContext,
      locale,
      antLocale,
    };
  }

  render() {
    const { locale } = this.props.context;

    return (
      <ConfigProvider
        locale={localeData[locale || "en"]}
        antLocale={this.props.context.antLocale}
        theme={{
          token: {
            fontFamily: "zantrokeregular",
          },
        }}
      >
        {React.Children.only(this.props.children)}
      </ConfigProvider>
    );
  }
}

export default App;
