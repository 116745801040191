import { combineReducers } from "redux";
import global from "./global";
import intl from "./intl";
import recipe from "./recipe";
import rawMaterials from "./rawMaterials";
import filterSearch from "./filterSearch";
import container from "./container";
import components from "./components";
import product from "./product";
import shopping_list from "./shopping_list";
import user from "./user";
import login from "./login";
import register from "./register";
import resetPassword from "./resetPassword";
import setPassword from "./setPassword";
import dilution from "./dilution";
import piechart from "./piechart";
import ifra from "./ifra";
import pdfmodal from "./pdfmodal";

export default combineReducers({
  global,
  intl,
  recipe,
  rawMaterials,
  filterSearch,
  container,
  components,
  product,
  user,
  login,
  register,
  resetPassword,
  setPassword,
  dilution,
  piechart,
  shopping_list,
  ifra,
  pdfmodal,
});
